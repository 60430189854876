<template>
  <!-- 新增分区类别 -->
  <div class="page">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="分区类别" prop="typeName">
        <el-input v-model="ruleForm.typeName" size=""></el-input>
      </el-form-item>
      <el-form-item label="排序字段" prop="sort">
        <el-input
          v-model.number="ruleForm.sort"
          size=""
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
        ></el-input>
      </el-form-item>
      <!-- 按钮 -->
      <el-form-item label="">
        <el-button type="info" @click="getBack">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >新增</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        typeName: "", // 分区类别
        sort: "", // 排序字段
      },
      // 验证规则
      rules: {
        typeName: [
          { required: true, message: "请输入分区类别", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序字段", trigger: "blur" }],
      },
    };
  },
  methods: {
    // 点击新增
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post("/admin/certificateType/addFirmType", this.ruleForm)
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.resetForm(formName);
              } else {
                this.$message({
                  type: "errer",
                  message: "添加失败!",
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 返回
    getBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
  width: 400px;
}
</style>
